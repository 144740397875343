.booking-panel{
    font-size: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: aliceblue;
    margin: 0 10px;
    border-radius: 10px;
    text-align: center;
    border-radius: 10px;
    overflow: auto;
}

.modal-refund__footer{
    display: flex;
}
.modal-refund__footer-right{
    width: fit-content;
}
.modal-refund__footer-left{
    text-align: left;
    width: fit-content;
    margin-right: 1rem;
}
.modal-refund__footer-right{
    margin-left: auto;
    text-align: right;
}